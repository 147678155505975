import axios from 'axios';
import apiConfig from '../apiConfig';
import { getAxiosInstance } from '../../utils/axiosApi';

const axiosInstance = getAxiosInstance({
  baseUrl: `${apiConfig.IAMUrl}`,
});

const searchUser = async ({
  userName,
  firstName,
  lastName,
  active,
  email,
  roleID,
  countryCode,
  requestCategoryId,
  regionId,
  locationId,
  businessLineId,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];

  if (userName && userName.trim() !== '') {
    queryParams.push(`userName=${encodeURIComponent(userName.trim())}`);
    queryParams.push('userNameOP=Contains');
  }

  if (firstName && firstName.trim() !== '') {
    queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
    queryParams.push('firstNameOP=Contains');
  }

  if (lastName && lastName.trim() !== '') {
    queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
    queryParams.push('lastNameOP=Contains');
  }

  if (email && email.trim() !== '') {
    queryParams.push(`email=${encodeURIComponent(email.trim())}`);
    queryParams.push('emailOP=Contains');
  }

  if (roleID) {
    queryParams.push(`roleId=${roleID}`);
  }
  if (businessLineId) {
    queryParams.push(`businessLineId=${businessLineId}`);
  }
  if (countryCode) {
    queryParams.push(`countryCode=${countryCode}`);
  }
  if (requestCategoryId) {
    queryParams.push(`requestCategoryId=${requestCategoryId}`);
  }
  if (regionId) {
    queryParams.push(`regionCode=${regionId}`);
  }
  if (locationId) {
    queryParams.push(`locationId=${locationId}`);
  }

  if (active != null) {
    queryParams.push(`active=${active}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }
  try {
    const response = await axiosInstance.post(
      `/User/users/search/by?${queryParams.join('&')}`
    );
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  // try {
  //   // const response = await axiosInstance.get(`/User/users/all?${queryParams.join('&')}`);
  //   // return response.data;
  // } catch (e) {
  //   if (!axios.isCancel(e)) {
  //     throw e;
  //   }
  // }

  return {};
};

const searchLADPUser = async ({
  isBVEmployee = true,
  firstName,
  lastName,
  email
}) => {
  const queryParams = [];

  if (firstName && firstName.trim() !== '') {
    queryParams.push(`firstName=${encodeURIComponent(firstName.trim())}`);
  }

  if (lastName && lastName.trim() !== '') {
    queryParams.push(`lastName=${encodeURIComponent(lastName.trim())}`);
  }

  if (email && email.trim() !== '') {
    queryParams.push(`email=${encodeURIComponent(email.trim())}`);
  }
  if (isBVEmployee) {
    queryParams.push(`isInternal=${isBVEmployee}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/employees/type/ldap/all?${queryParams.join('&')}`
    );
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserByUserId = async ({ userId }) => {
  try {
    const response = await axiosInstance.get(
      `/User/users/id/${userId}/object/all/role/all`
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const UpdateUser = async (updateUser) => {
  try {
    const response = await axiosInstance.post(
      '/User/detail/update',
      updateUser
    );
    return response.data;
  } catch (e) {
    throw new Error('Error getUserByUserId');
  }
};

const getAllRolesdata = async () => {
  try {
    const response = await axiosInstance.get('/User/roles/all');
    return response.data;
  } catch (e) {
    throw new Error('Error getUserMasterdata');
  }
};

const searchUserAccount = async ({
  username,
  usernameOp,
  loginType,
  textValue,
  restrictedClientIds,
  page,
  pageSize,
  sortBy,
}) => {
  const queryParams = [];
  if (username && username.trim() !== '') {
    queryParams.push(`username=${encodeURIComponent(username.trim())}`);

    if (usernameOp && usernameOp.trim() !== '') {
      queryParams.push(`usernameOP=${encodeURIComponent(usernameOp.trim())}`);
    } else {
      queryParams.push('usernameOP=Contains');
    }
  }

  if (loginType && loginType.trim() !== '') {
    queryParams.push(`loginType=${encodeURIComponent(loginType.trim())}`);
  }

  if (textValue && textValue.trim() !== '') {
    queryParams.push(`textValue=${encodeURIComponent(textValue.trim())}`);
  }

  if (Array.isArray(restrictedClientIds) && restrictedClientIds.length > 0) {
    queryParams.push(`restrictedClientIds=${restrictedClientIds.join(',')}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/useraccounts/all?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    // console.log(axios.isCancel);
    // console.log("axios request cancelled");
    // if (axios.isCancel(err)) {
    //   console.log("axios request cancelled");
    //   return {};
    // }

    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const getUserWithDetailsByUserID = async ({ userId }) => {
  try {
    const response = await axiosInstance.get(
      `/User/users/id/${userId}/object/all`
    );

    // console.log("Search job result");
    // console.log(response);
    // {
    //   User,
    //   UserObjects,
    //   RoleClaims
    // }
    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

/// ---- New APis
const searchUserForAutoComplete = async ({
  name,
  roleName,
  requestCategoryId,
  countryCode,
  regionCodes,
  locationId,
  businessLineId,
  page,
  pageSize
}) => {
  const queryParams = [];

  if (name && name.trim() !== '') {
    queryParams.push(`name=${encodeURIComponent(name.trim())}`);
  }

  if (roleName && roleName.trim() !== '') {
    queryParams.push(`roleName=${encodeURIComponent(roleName.trim())}`);
  }

  if (requestCategoryId && !Array.isArray(requestCategoryId)) {
    queryParams.push(`requestCategoryId=${encodeURIComponent(requestCategoryId)}`);
  }

  if (Array.isArray(requestCategoryId) && requestCategoryId?.length) {
    queryParams.push(requestCategoryId.map((arrayItem) => `requestCategoryId=${encodeURIComponent(arrayItem)}`).join('&'));
  }

  if (countryCode && countryCode.trim() !== '') {
    queryParams.push(`countryCode=${encodeURIComponent(countryCode.trim())}`);
  }

  if (Array.isArray(regionCodes) && regionCodes.length > 0) {
    queryParams.push(`regionCodes=${regionCodes.join(',')}`);
  }

  if (locationId) {
    queryParams.push(`locationId=${locationId}`);
  }

  if (businessLineId) {
    queryParams.push(`businessLineId=${businessLineId}`);
  }

  if (page) {
    queryParams.push(`currentPage=${page}`);
  }

  if (pageSize) {
    queryParams.push(`pageSize=${pageSize}`);
  }

  try {
    const response = await axiosInstance.get(
      `/User/users/namesearch/by?${queryParams.join('&')}`
    );

    return response.data;
  } catch (e) {
    throw new Error('Error searchUserForAutoComplete');
  }
};

export const createUserPost = async (userData) => {
  try {
    const response = await axiosInstance.post(
      '/User/CreateWithApplication',
      userData
    );

    return response.data;
  } catch (e) {
    throw new Error('Error createRequestPost');
  }
};

const updateUserDetail = async (userDetail) => {
  try {
    const response = await axiosInstance.post(
      '/User/detail/Application/update',
      userDetail
    );
    return response.data;
  } catch (e) {
    throw new Error('Error updateUserDetail');
  }
};

const resetClientPassword = async ({ tokenId, newPassword }) => {
  if (!tokenId || !newPassword) {
    throw new Error('Please check your password');
  }

  try {
    await axiosInstance.post('/user/customers/resetpassword', {
      RecoveryId: tokenId,
      Password: newPassword,
    });

    return true;
  } catch (e) {
    throw new Error('Please check your password');
  }
};

const UpdateUserProfile = async ({ user, updateObjects }) => {
  try {
    const response = await axiosInstance.post('/User/profile/update', {
      User: user,
      UpdateObjects: updateObjects,
    });
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUserProfile');
  }
};

const getOrCreateIEvalUser = async ({ email }) => {
  try {
    const response = await axiosInstance.post('/User/getOrCreateIEvalUser', {
      Email: email
    });
    return response.data;
  } catch (e) {
    throw new Error('Error UpdateUserProfile');
  }
};

const getUserDataWithRoleByUserID = async ({ userId }) => {
  try {
    const response = await axiosInstance.post(
      `/User/users/id/${userId}/object/all/role/all`
    );

    return response.data;
  } catch (e) {
    if (!axios.isCancel(e)) {
      throw e;
    }
  }

  return {};
};

const UserApi = {
  searchUser,
  searchLADPUser,
  getAllRolesdata,
  getUserWithDetailsByUserID,
  searchUserAccount,
  getUserByUserId,
  UpdateUser,
  resetClientPassword,

  searchUserForAutoComplete,
  createUserPost,
  updateUserDetail,
  UpdateUserProfile,
  getOrCreateIEvalUser,
  getUserDataWithRoleByUserID
};

export default UserApi;

import { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem, Typography, Popover, Stack, useTheme } from '@mui/material';
import ChevronDownIcon from '../../../asset/icons/ChevronDown';
import ChevronRightIcon from '../../../asset/icons/ChevronRight';

const NavItem = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    onNavigate,
    isAuthorized,
    collapsed,
    ...other
  } = props;
  const [open, setOpen] = useState(openProp);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopver = Boolean(anchorEl);
  const theme = useTheme();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = active ? 24 : 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }
  const showPopover = (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: 'none',
      }}
      open={openPopver}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
  // transformOrigin={{
  //   vertical: 'center',
  //   horizontal: 'right',
  // }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Typography sx={{ px: 2, py: 0.5 }}>{title}</Typography>
    </Popover>
  );

  // Branch
  if (children) {
    return (
      <>
        <Stack direction="row">
          <ListItem
            disableGutters
            sx={{
              display: 'block',
              py: 0,
            }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            {...other}
          >
            <Button
              endIcon={
                !open ? <ChevronRightIcon fontSize="small" color="text.secondary" /> : <ChevronDownIcon fontSize="small" color="text.secondary" />

          }
              onClick={handleToggle}
              startIcon={icon}
              sx={{
                color: `${theme.palette.text.primary}`,
                // color: '#f4f5f7',
                fontWeight: 'medium',
                justifyContent: 'flex-start',
                pl: `${paddingLeft}px`,
                pr: '8px',
                py: '12px',
                textAlign: 'left',
                textTransform: 'none',
                width: `${collapsed ? '0px' : '100%'}`,
                '& svg': {
                  color: 'action.active',
                },
              }}
              variant="text"
            >
              <Box sx={{ flexGrow: 1 }}>{collapsed ? '' : title}</Box>
              {info}
            </Button>
            <Collapse in={open}>{children}</Collapse>
          </ListItem>
          {collapsed ? showPopover : null}
        </Stack>
      </>
    );
  }

  // Leaf
  return (
    <>
      <Stack
        direction="row"
        sx={{
          ...(active && {
            borderLeftColor: theme.palette.primary.main,
            borderLeftWidth: 6,
            borderLeftStyle: 'solid',
            // borderTopLeftRadius: 2,
            // borderBottomLeftRadius: 2,
            ml: -1
          }),
        }}
      >
        <ListItem
          disableGutters
          sx={{
            display: 'flex',
            py: 0,
            ml: active ? -0.75 : 0
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Button
            component={path && RouterLink}
            startIcon={icon}
            onClick={() => onNavigate(path)}
          // endIcon={active && <FiberManualRecordIcon className="activeIcon" sx={{ width: 10 }} />}
            sx={{
              borderRadius: '0 12px 12px 0',
              color: `${theme.palette.text.primary}`,
              fontWeight: '500',
              justifyContent: 'flex-start',
              textAlign: 'left',
              pl: `${paddingLeft}px`,
              pr: '8px',
              py: '12px',
              textTransform: 'none',
              width: '100%',
              // width: `${collapsed ? '0px' : '100%'}`,
              '& svg': {
                color: 'action.active',
              },
              // '&::after': {
              //   content: '""',
              //   display: 'block',
              //   position: 'absolute',
              //   top: 'calc(50% - 3px)',
              //   left: '19px',
              //   bottom: 0,
              //   border: '1px solid #333',
              //   borderRadius: '50%',
              //   width: '5px',
              //   height: '5px',
              //   backgroundColor: 'primary.main',
              // },
              '& svg.activeIcon': {
                color: 'success.main',
              },
              ...(active && {
                // color: "primary.main",
                fontWeight: '700',
                // '& svg': {
                //   color: 'warning.main',
                // },
                background: `${theme.palette.background.accent}`
              }),
            }}
            variant="text"
            to={path}
          >
            <Box sx={{ flexGrow: 1 }}>{collapsed ? '' : title}</Box>
            {info}
          </Button>
        </ListItem>
        {collapsed ? showPopover : null}
      </Stack>
    </>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
